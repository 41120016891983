export  const typeFormVO = () => {
  return {
    id: '',
    name: '',
    pid: null,
    sorter: '',
    type: '',
    tag: '',
    plotType: '',
    orgId: ''
  }
}