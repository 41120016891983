<template>
  <page-layout class="fs-page">
    <a-row :gutter="24">
      <a-col :span="6" style="background: white;padding: 0">
        <Tree ref="treeRef" :sup_this="sup_this"
              @defaultNodeKey="defaultNodeKey" @monitoringPointEditSuccess="monitoringPointEditSuccess"></Tree>
      </a-col>
      <a-col :span="18">
        <a-card
          style="width:100%"
          :tab-list="tabListNoTitle"
          :active-tab-key="noTitleKey"
          @tabChange="key => onTabChange(key, 'noTitleKey')"
        >
          <div v-if="noTitleKey === 'basicsInfo'">
            <a-card title="基础信息" :bordered="true" class="margin-button" :headStyle="{background: '#F5F5F5'}">
              <div slot="extra">
                <a-button type="primary" style="margin-left: 16px;" v-if="show" @click="backNodeList">返回</a-button>
                <a-button style="margin-left: 16px;" type="primary" @click="saveBasicInfo">保存</a-button>
              </div>
              <a-form-model class="" :model="form" layout="inline" :rules="rules">
                <a-row :gutter="24">
                  <a-col :span="8">
                    <a-form-item label="名称" required prop="date1">
                      <a-input style="width: 245px" v-model="form.name"></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item label="编号">
                      <a-input style="width: 240px" v-model="form.no" placeholder="请输入编号"></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item label="类型">
                      <a-select v-model="form.type" style="width: 240px">
                        <a-select-option v-for="item in selectOptions" :value="item.value" :key="item.value">
                          {{item.name}}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item label="部门" style="margin-left: 10px;">
                      <a-tree-select
                        style="width: 245px"
                        v-model="form.orgId"
                        :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                        :treeData="orgTree"
                        allowClear
                        placeholder="请选择，默认当前登录用户所在部门"
                      ></a-tree-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item label="区域">
                      <a-tree-select
                        style="width: 100px"
                        v-model="form.areaId"
                        :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
                        :treeData="areaTree"
                        allowClear
                        placeholder="请选择省市县"
                      ></a-tree-select>
                      <a-input style="width: 135px; margin-left: 5px;" v-model="form.location"
                               placeholder="请输入详细地址"></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item label="标签">
                      <a-input style="width: 240px" placeholder="请输入标签" v-model="form.tag"></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item label="标会类型">
                      <a-select v-model="form.plotType" style="width: 225px">
                        <a-select-option v-for="item in plotType" :value="item.value" :key="item.value">
                          {{item.name}}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="8">
                    <a-form-item label="全景图">
                      <a-input style="width: 230px" placeholder="请输入全景图链接" v-model="form.panoramicUrl"></a-input>
                    </a-form-item>
                  </a-col>
                  <a-col :lg="24" :md="24" :sm="24">
                    <a-form-item label="">
                      <qiniu-image-upload
                        ref="qiniuImageRef"
                        @getImageData="getImageData"
                        :document-dir-id="1"
                        :multiSelection="true"
                        type="edit"
                        :items="form.images"
                      ></qiniu-image-upload>
                    </a-form-item>
                  </a-col>
                  <a-col :lg="24" :md="24" :sm="24">
                    <a-form-item label="">
                      <qiniu-file-upload
                        ref="qiniuFileRef"
                        @getFileData="getFileData"
                        :document-dir-id="2"
                        :multiSelection="true"
                        type="edit"
                        :items="form.files"
                      ></qiniu-file-upload>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-form-model>
            </a-card>
            <a-card title="扩展信息" :bordered="true" class="margin-button" :bodyStyle="{padding: '10px 10px'}"
                    :headStyle="{background: '#F5F5F5'}">
              <div slot="extra">
                <a-button style="margin-left: 16px;" type="primary" @click="$refs.extendInfoRef.handleAdd()">新增
                </a-button>
              </div>
              <ExtendInfo ref="extendInfoRef" :instanceId="searchParams.id" systemModule="monitoring_point"></ExtendInfo>
            </a-card>
          </div>
          <div v-else-if="noTitleKey === 'monitor'">
            <MetricsMonitoring :pointId="searchParams.id"></MetricsMonitoring>
          </div>
          <div v-else-if="noTitleKey === 'technology'">
            <view3-d-control :configurationCode="searchParams.id" :identify="true"></view3-d-control>
          </div>
          <div v-else-if="noTitleKey === 'nodeList'">
            <a-card>
              <a-row :gutter="12">
                <a-col :sm="24" :md="12" :lg="8" :xl="6" @click="handleStatistic('total')">
                  <a-card hoverable>
                    <a-statistic
                      title="全部"
                      :value=statisticsVO.totalNumber
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="6" @click="handleStatistic('warn')">
                  <a-card hoverable>
                    <a-statistic
                      title="预警"
                      :value=statisticsVO.warnNumber
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="6" @click="handleStatistic('fault')">
                  <a-card hoverable>
                    <a-statistic
                      title="故障"
                      :value=statisticsVO.faultNumber
                      style="text-align: center"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
                <a-col :sm="24" :md="12" :lg="8" :xl="6" @click="handleStatistic('normal')">
                  <a-card hoverable>
                    <a-statistic
                      title="正常"
                      :value=statisticsVO.normalNumber
                      style="text-align: center;"
                    >
                    </a-statistic>
                  </a-card>
                </a-col>
              </a-row>
              <a-row>
                <a-col :span="24">
                  <div class="fs-search-box" style="margin-top: 20px">
                    <div class="table-page-search-wrapper">
                      <a-form layout="inline">
                        <a-form-item label="">
                          <a-radio-group v-model="searchParams.type" @change="searchNodeList">
                            <a-radio-button value="">全部</a-radio-button>
                            <a-radio-button value="DEVICE_FACILITY">设备/设施</a-radio-button>
                            <a-radio-button value="OTHER">其他</a-radio-button>
                          </a-radio-group>
                        </a-form-item>
                        <a-form-item label="节点名称">
                          <a-input style="width:200px" v-model="searchParams.name" placeholder="输入节点名称"></a-input>
                        </a-form-item>
                        <a-form-item class="f-r m-r-none">
                          <a-button class="m-l-6" type="primary" @click="searchNodeList" icon="search">查询</a-button>
                        </a-form-item>
                      </a-form>
                    </div>
                  </div>
                  <a-table :columns="columns" :data-source="pointList"
                           bordered
                           @change="tableChange"
                           size="middle"
                           :pagination="pagination"
                           :rowKey="(record) => record.id">
                    <template slot="_index" slot-scope="text, record, index">
                      {{ searchParams.page * searchParams.size + index + 1 }}
                    </template>
                    <template slot="fullName" slot-scope="text, record">
                      <a v-if="record.fault || record.warn" @click="onClickRow(record)">{{record.fullName}}</a>
                      <span v-else>{{record.fullName}}</span>
                    </template>
                  </a-table>
                </a-col>
              </a-row>
            </a-card>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-modal v-model="visible" :title="title" :footer="null" :width="1000">
      <MetricsMonitoring ref="metricsMonitoringRef"></MetricsMonitoring>
    </a-modal>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import SERVICE_URLS from '@/api/service.url'
  import QiniuImageUpload from '@/components/QiniuUpload/QiniuImageUpload'
  import QiniuFileUpload from '@/components/QiniuUpload/QiniuFileUpload'
  import Tree from './Tree/List'
  import ExtendInfo from './ExtendInfo/ExtendInfoList'
  import { tabListNoTitle, selectOptions, plotType, columns } from './common/common'
  import { formVO } from './common/formVO'
  import MetricsMonitoring from './MetricsMonitoring'

  export default {
    name: 'monitoringPoint',
    mixins: [],
    components: {
      PageLayout,
      QiniuImageUpload,
      QiniuFileUpload,
      Tree,
      ExtendInfo,
      MetricsMonitoring
    },
    data () {
      return {
        tabListNoTitle: tabListNoTitle(),
        noTitleKey: 'basicsInfo',
        selectOptions: selectOptions(),
        questionLabelCol: { span: 5 },
        questionWrapperCol: { span: 17 },
        selectTreeId: '1',
        sup_this: this,
        searchParams: {
          page: 0,
          size: 10,
          direction: '',
          order: '',
          id: '',
          name: '',
          selectType: '',
          level: 0,
          type: 'DEVICE_FACILITY'
        },
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        form: formVO(),
        plotType: plotType(),
        rules: {
          name: [
            { required: true, message: '名称为必填', trigger: 'change' }
          ]
        },
        areaTree: [],
        orgTree: [],
        pointList: [],
        detail: null,
        columns: columns(),
        show: false,
        configurationCode: '',
        statisticsVO: {},
        value: 'a',
        visible: false,
        title: ''
      }
    },
    created () {
      this.getAreaTree()
      this.getOrgTree()
    },
    methods: {
      getAreaTree () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.depotApi.getAreaTree,
          noTips: true,
          success: (data) => {
            this.areaTree = data.body
          }
        })
      },
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      // 默认选中一级监测点
      defaultNodeKey (key) {
        this.searchParams.id = key
        this.searchParams.selectType = 'total'
        this.getDetail()
      },
      // 点击统计卡片
      handleStatistic (val) {
        if (!this.searchParams.id) {
          this.$message.warning('请先选择左侧节点后重试!')
          return
        }
        this.searchParams.selectType = val
        this.searchNodeList()
      },
      // 右侧详情页面tab切换
      onTabChange (key, type) {
        if (key === 'nodeList') {
          this.searchNodeList()
          this.statistics()
        }
        this[type] = key
      },
      // 查询选中节点的详情
      getDetail () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.view,
          noTips: true,
          params: {
            id: this.searchParams.id
          },
          success: (data) => {
            Object.assign(this.form, data.body)
            /*图片初始化展示处理赋值Ids*/
            this.form.images.forEach((item) => {
              this.form.imageIds.push(item.id)
            })
            /*文件初始化展示处理赋值Ids*/
            this.form.files.forEach((item) => {
              this.form.fileIds.push(item.id)
            })
          }
        })
      },
      // 查询节点列表
      searchNodeList () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.search_node_list,
          noTips: true,
          data: this.searchParams,
          success: (data) => {
            this.pointList = data.body.content
            this.pagination.total = data.body.totalElements
            this.pagination.current = data.body.number + 1
            this.pagination.pageSize = data.body.size
            this.searchParams.size = data.body.size
            this.searchParams.page = data.body.number
          }
        })
      },
      tableChange (pagination, filters, sorter) {
        this.setSearchParamsPage(pagination)
        if (sorter.order === 'ascend') {
          this.searchParams.direction = 'ASC'
        } else {
          this.searchParams.direction = 'DESC'
        }
        this.searchParams.order = sorter.field
        this.searchNodeList()
      },
      setSearchParamsPage (pagination) {
        this.searchParams.page = pagination.current - 1
        this.searchParams.size = pagination.pageSize
      },
      // 子节点列表编辑
      handleEdit (id) {
        this.show = true
        this.restoreDefault()
        this.searchParams.id = id
        this.getDetail()
      },
      // 基础信息保存
      saveBasicInfo () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.update_detail,
          noTips: true,
          data: this.form,
          success: (data) => {
            this.$message.success('保存成功')
            this.addSuccessCallback()
          }
        })
      },

      //右侧基础信息保存成功返回方法
      addSuccessCallback () {
        this.searchParams.id = this.selectTreeId
        this.getDetail()
      },

      // 监测点编辑成功返回方法 刷新右侧详情页面
      monitoringPointEditSuccess () {
        this.getDetail()
      },
      /*上传图片回调方法*/
      getImageData (data) {
        this.form.imageIds = []
        data.forEach((item) => {
          this.form.imageIds.push(item.id)
        })
      },
      /*上传文件回调方法*/
      getFileData (data) {
        this.form.fileIds = []
        data.forEach((item) => {
          this.form.fileIds.push(item.id)
        })
      },
      // 统计监测点数量
      statistics () {
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.statistics,
          noTips: true,
          data: { id: this.searchParams.id },
          success: (data) => {
            this.statisticsVO = data.body
          }
        })
      },
      // 恢复默认 列表 统计 form
      restoreDefault () {
        this.noTitleKey = 'basicsInfo'
        this.pointList = []
        this.statisticsVO = {}
        this.show = false
        this.form = {
          id: '',
          areaId: undefined,
          name: '',
          location: '',
          memo: '',
          no: '',
          orgId: undefined,
          type: 'DEVICE_FACILITY',
          pid: '',
          plotType: 'marker',
          files: [],
          images: [],
          imageIds: [],
          fileIds: []
        }
      },
      onClickRow (record) {
        this.visible = true
        this.title = record.name
        this.$nextTick(() => {
          this.$refs.metricsMonitoringRef.getListGroupByTag(record.id)
        })
      }
    }
  }
</script>

<style scoped>
  .ant-col-8 {
    margin-bottom: 15px;
  }

  .margin-button {
    margin-bottom: 24px;
  }

  .ant-advanced-search-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
  }

  .ant-advanced-search-form .ant-form-item {
    display: flex;
  }

  .ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
  }
</style>
